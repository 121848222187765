import dayjs from 'dayjs'
import { makeSafeVariants } from 'design/image-utils'
import { IBackgroundImageProps } from 'gatsby-background-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import { useProductOfferLander } from '~/cms/hooks/useProductOfferLander'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import makeRichTextOptions from '~/cms/rich-text'
import type { CMS } from '~/cms/types'
import ModuleMemberPerks, {
  sharedIconProps,
} from '~/components/ModuleMemberPerks/ModuleMemberPerks'
import ModuleOptIn from '~/components/ModuleOptIn/ModuleOptIn'
import ModuleSocialCards from '~/components/ModuleSocialCards/ModuleSocialCards'
import ModuleText from '~/components/ModuleText/ModuleText'
import { useAcquisitonBannerContent } from '~/hooks/useBannerContent'
import SEO from '~/layouts/seo'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import setLanderCookies from '~/utils/lander'
import { Frames } from '../CheckoutFlow/constants'
import { IconQuality, Text, Lockup, rem, IconValue } from '@butcherbox/freezer'

const FORM_SECTION_HEIGHT = 576

const GetStartedPromoLander = ({
  allowSkipEmailGate,
  memberPerksImage,
  pageData,
  uniquePageId,
  shouldCollectEmail = true,
  destinationFrame,
}: {
  allowSkipEmailGate?: boolean
  memberPerksImage: IBackgroundImageProps['fluid']
  pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
  uniquePageId: string
  shouldCollectEmail?: boolean
  destinationFrame?: Frames
}) => {
  const {
    content: {
      coupon,
      moduleOptInBottom,
      moduleOptInTop,
      fullWidthTextModule,
      offer,
      socialCards,
    },
    seo,
  } = useProtectedPage(pageData, useProductOfferLander, {
    setAcquisitionBanner: false,
  })
  const { setContent } = useAcquisitonBannerContent()

  React.useEffect(() => {
    setLanderCookies({
      lander: uniquePageId,
      offer: offer?.id
        ? {
            id: offer?.id,
            expiry: dayjs(offer?.expiration).endOf('day'),
          }
        : undefined,
      offerText: offer?.bannerText,
      couponCode: coupon?.code,
    })

    setContent(
      <Text
        color="white"
        component="p"
        data-what="banner-text"
        variant="H3Bold"
      >
        <span style={{ textTransform: 'uppercase' }}>
          {offer?.bannerText || 'Special offer for new members'}
        </span>
      </Text>
    )
  }, [offer, uniquePageId, setContent, coupon])

  return (
    <UnauthenticatedLayout
      disableHeader
      disablePublicity
      SEO={
        <SEO image={seo?.image?.fixed?.src} shouldIndex={shouldCollectEmail} />
      }
    >
      <ModuleOptIn
        allowSkipEmailGate={allowSkipEmailGate}
        backgroundColor="bb.ivory"
        body={moduleOptInTop.body}
        cta={moduleOptInTop?.cta || null}
        dataWhere="optin_top"
        destinationFrame={destinationFrame}
        disclaimers={moduleOptInTop.disclaimers}
        header={moduleOptInTop.header}
        headerColor="slate"
        heading="h1"
        id="get-started-optin"
        imgAlt={moduleOptInTop?.imageAlt}
        imgOverlayBackground={makeSafeVariants({
          imageMobile: moduleOptInTop?.imageOverlayBackgroundMobile,
          imageDesktop: moduleOptInTop?.imageOverlayBackgroundDesktop,
        })}
        shouldCollectEmail={shouldCollectEmail}
        variant="Card Overlay"
      />

      {fullWidthTextModule.header || fullWidthTextModule.body?.raw ? (
        <ModuleText aria-labelledby="get-started-promo">
          <Lockup textAlign="center">
            <IconQuality display="inlineBlock" size="regular" variant="duo" />
            {fullWidthTextModule.header ? (
              <Text component="h2" id="get-started-promo" variant="Subhead1">
                {fullWidthTextModule.header}
              </Text>
            ) : null}
            {fullWidthTextModule.body?.raw ? (
              <Text variant="Body1Regular">
                {renderRichText(
                  fullWidthTextModule.body,
                  makeRichTextOptions()
                )}
              </Text>
            ) : null}
          </Lockup>
        </ModuleText>
      ) : null}
      <ModuleMemberPerks
        as="section"
        bgFluid={memberPerksImage}
        header="What is ButcherBox?"
        id="get-started-member-perks"
        perks={[
          {
            renderIcon: () => <IconQuality {...sharedIconProps} />,
            header: 'quality',
            body:
              "We're committed to premium meat: 100% grass-fed beef, free-range organic chicken, pork raised crate-free, and wild-caught seafood.",
          },
          {
            renderIcon: () => <IconValue {...sharedIconProps} />,
            header: 'value',
            body:
              'Our goal is to make high-quality meat accessible to as many people as possible. We’re able to deliver you the best products for less than $6 per meal. And shipping is free!',
          },
          {
            renderIcon: () => <IconQuality {...sharedIconProps} />,
            header: 'convenience',
            body:
              'Get our meat delivered, for free, right to your door. Each box perfectly packed and portioned for your needs.',
          },
        ]}
        subheader="Better Meat for a Better You"
      />
      <ModuleOptIn
        allowSkipEmailGate={allowSkipEmailGate}
        backgroundColor="white"
        body={moduleOptInBottom.body}
        cta={moduleOptInBottom.cta || null}
        dataWhere="optin_bottom"
        destinationFrame={destinationFrame}
        disclaimers={moduleOptInBottom.disclaimers}
        header={moduleOptInBottom.header}
        headerColor="slate"
        heading="h2"
        height={{ base: 'auto', tablet: rem(FORM_SECTION_HEIGHT) }}
        id="get-started-optin-card"
        img={moduleOptInBottom?.imageSplitCard?.fluid}
        imgAlt={moduleOptInBottom.imageAlt}
        shouldCollectEmail={true}
        variant="Card"
      />
      <ModuleSocialCards
        as="section"
        cards={socialCards}
        id="get-started-social-proof"
      />
    </UnauthenticatedLayout>
  )
}

export default GetStartedPromoLander
